import axios from 'axios'

const api = axios.create({
    baseURL: 'http://localhost:4000',
    withCredentials: true
})

export const getChatRooms = async (queries) => {
    try {
        const q = queries ? `?${queries}` : ""
        const response = await api.get(`/chat-rooms${q}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getTotalChatRooms = async (queries) => {
    try {
        const q = queries ? `?${queries}` : ""
        const response = await api.get(`/chat-rooms/total${q}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getTotalActiveChatRooms = async (queries) => {
    try {
        const q = queries ? `?${queries}` : ""
        const response = await api.get(`/chat-rooms/active/total${q}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const createChatMessage = async (chat_room_id, sender_id, text) => {
    try {
        const data = {
            chat_room_id,
            sender_id,
            text,
        }

        const response = await api.post('/chat-messages', data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const loginAdmin = async (email, password) => {
    try {
        const data = {
            email,
            password
        }

        const response = await api.post('/users/login', data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getUserProfile = async () => {
    try {
        const response = await api.get('/users/profile')
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getTotalUsers = async (queries) => {
    try {
        const q = queries ? `?${queries}` : ""
        const response = await api.get(`/users/total${q}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const readChatRoom = async (chat_room_id) => {
    try {
        const response = await api.post(`/chat-rooms/read-chat-room/${chat_room_id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const endChat = async (chat_room_id) => {
    try {
        const response = await api.patch(`/chat-rooms/end/${chat_room_id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getChatKeywords = async () => {
    try {
        const response = await api.get(`/chat-keywords`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getChatKeyword = async (id) => {
    try {
        const response = await api.get(`/chat-keywords/details/${id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const createChatKeyword = async (word, reply_text) => {
    try {
        const data = {
            word,
            reply_text
        }
        const response = await api.post(`/chat-keywords`, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const updateChatKeyword = async (id, word, reply_text) => {
    try {
        const data = {
            word,
            reply_text
        }
        const response = await api.patch(`/chat-keywords/details/${id}`, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const enableChatKeyword = async (id) => {
    try {
        const response = await api.patch(`/chat-keywords/enable/${id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const disableChatKeyword = async (id) => {
    try {
        const response = await api.patch(`/chat-keywords/disable/${id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const createChatReplyOptions = async (chat_keyword_id, texts, type) => {
    try {
        const data = {
            chat_keyword_id,
            texts,
            type: "button",
        }
        const response = await api.post(`/chat-reply-options/multiple`, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const updateChatReplyOptions = async (chat_keyword_id, texts) => {
    try {
        const data = {
            chat_keyword_id,
            texts,
            type: "button",
        }
        const response = await api.patch(`/chat-reply-options/multiple`, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getUsers = async () => {
    try {
        const response = await api.get("/users/all")
        return response.data;
    } catch (error) {
        console.error(error)
    }
}
